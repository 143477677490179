import { ChevronRight } from "@mui/icons-material";
import { ListItem, Typography } from "@mui/material";
import { UserBankAccountStatus } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { StyledFlex } from "Components/Shared/StyledComponents";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import type { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VerifiedRow = styled(Row)`
  > p {
    color: ${props => props.theme.palette.primary.dark};
  }
`;

const StyledListItem = styled(ListItem)<{ $isVerified: boolean }>`
  padding-right: 0;
  cursor: ${props => (props.$isVerified ? "default" : "pointer")};
  div {
    color: ${props => props.theme.palette.primary.dark};

    ${props => props.theme.breakpoints.up("md")} {
      &:hover {
        color: ${props => props.theme.palette.primary.main};
      }
    }
  }
`;

const RightSide = styled(StyledFlex)`
  color: ${props => props.theme.palette.primary.main}!important;
`;

export const BankAccountRowLink: FC<
  PropsWithChildren<{
    item: BankAccountListItem;
  }>
> = ({ item }) => {
  const { environment, iban, bankAccountID } = item;

  const appUrl = getAppUrl();

  // TODO: Will be verified status used?
  const isVerified = [
    UserBankAccountStatus.Verified,
    UserBankAccountStatus.VerifiedIBANParsingError,
  ].includes(UserBankAccountStatus.Verified);

  // TODO: Will be used?
  const statusTest = environment; //getStatusText(item.status);

  return (
    <StyledListItem $isVerified={isVerified}>
      {isVerified ? (
        <VerifiedRow>
          <Typography>{iban}</Typography>
          <RightSide>
            <Typography fontWeight={600}>{statusTest}</Typography>
          </RightSide>
        </VerifiedRow>
      ) : (
        <UnstyledLink
          to={`${appUrl("bank-address-detail")}/${bankAccountID}`}
          style={{ width: "100%" }}
        >
          <Row>
            <Typography>
              {/*item.formattedAccountNumber ?? "Neověřený účet"*/}
            </Typography>
            <RightSide>
              <Typography fontWeight={600}>{statusTest}</Typography>
              <ChevronRight />
            </RightSide>
          </Row>
        </UnstyledLink>
      )}
    </StyledListItem>
  );
};

// function getStatusText(status: UserBankAccountStatus) {
//   switch (status) {
//     case UserBankAccountStatus.Verified:
//     case UserBankAccountStatus.VerifiedIBANParsingError:
//       return "Ověřený";
//     case UserBankAccountStatus.NewToVerify:
//     case UserBankAccountStatus.New:
//       return "Platební údaje";
//     case UserBankAccountStatus.Rejected:
//       return "Zamítnutý";
//     default:
//       return "";
//   }
// }

const CreateBankAccountTypography = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  font-weight: 600;
`;

export const CreateBankAccountRowLink: React.FunctionComponent<
  React.PropsWithChildren<{
    onClick?: () => void;
  }>
> = ({ onClick }) => {
  return (
    <StyledListItem divider $isVerified={false} onClick={onClick}>
      <Row>
        <CreateBankAccountTypography>
          Přidat nový účet
        </CreateBankAccountTypography>
        <RightSide>
          <ChevronRight />
        </RightSide>
      </Row>
    </StyledListItem>
  );
};
