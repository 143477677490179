import { JWT_KEY } from "Utils/AuthUtils";
import { setHotjarIdentification } from "Utils/HotjarUtils";
import * as Sentry from "@sentry/react";
import { logError } from "ErrorService";
import { getSystemVersion } from "Utils/VersioningUtils";

const isSentryEnabled = import.meta.env.VITE_IS_SENTRY_ENABLED === "true";

export function trackUserIdentification() {
  setHotjarIdentification();
  setSentryIdentification();
}

function setSentryIdentification() {
  if (!isSentryEnabled) {
    return;
  }
  try {
    const user = parseSentryUserFromJWT();
    console.log("setSentryIdentification", user);

    Sentry.setTag("version", getSystemVersion());

    if (!!user) {
      Sentry.setUser(user);
      Sentry.setTag("user.login", user.username);

      Sentry.withScope(scope => {
        scope.setUser(user);
        scope.setTag("user.login", user.username);
      });
    }
  } catch (error) {
    logError(error as Error);
  }
}

export function parseSentryUserFromJWT() {
  var token = localStorage.getItem(JWT_KEY);

  if (!token) {
    return;
  }

  var login = JSON.parse(window.atob(token.split(".")[1])).unique_name;

  const user = {
    id: login,
    email: login,
    username: login,
  };

  return user;
}
