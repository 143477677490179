import { SignUpForm, FormData } from "Components/Auth/SignUp/SignUpForm";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootStateType } from "State/Store";
import { Resources, useServerError } from "Translations/Resources";
import { resetError, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import { signUpPasswordlessAsync } from "State/Auth/SignUp/SignUpPasswordlessState";
import { LocalStorageKeys } from "Utils/LocalStorageKeys";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";

export const SignUpPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const { translateError } = useServerError(
    Resources.SignUp.Error,
    Resources.SignUp.Error.General,
  );

  const { isLoading, errorCode } = useSelector((e: RootStateType) => e.auth);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const affiliateCode = searchParams.get("affiliateCode");
  const email = searchParams.get("email");

  const isPasswordless = searchParams.get("isPasswordless") === "true";
  const voucherCode = searchParams.get("voucherCode");

  React.useEffect(() => {
    if (!!voucherCode) {
      localStorage.setItem(LocalStorageKeys.VoucherCodeSubsidy, voucherCode);

      // 👇️ delete each query param
      searchParams.delete("voucherCode");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  }, [voucherCode, searchParams, setSearchParams]);

  React.useEffect(() => {
    if (!!affiliateCode && !!email) {
      localStorage.setItem("affiliateCode", affiliateCode);
    }
  }, [affiliateCode, email]);

  const onSubmit = ({ login, password }: FormData) => {
    if (!!password) {
      dispatch(
        signUpAsync.request({
          model: {
            login,
            password,
            affiliateCode: localStorage.getItem("affiliateCode"),
          },
          navigate,
        }),
      );
    }

    if (isPasswordless) {
      dispatch(
        signUpPasswordlessAsync.request({
          model: {
            login,
            voucherCode: localStorage.getItem(
              LocalStorageKeys.VoucherCodeSubsidy,
            ),
          },
          navigate,
        }),
      );
    }
  };
  const [resetValue, toggleReset] = React.useState(true);
  const onFormReset = () => {
    toggleReset(!resetValue);
    dispatch(setSignInStep(SignInStep.Credentials));
  };
  return (
    <AuthPageLayout
      title={"Vytvořte si účet"}
      key={`${resetValue}`}
      isLoading={isLoading}
      pageType="sign-up"
    >
      <SignUpForm
        key={email}
        isLoading={isLoading}
        onSubmit={onSubmit}
        serverError={translateError(errorCode)}
        onReset={onFormReset}
        defaultLogin={email}
        isPasswordless={isPasswordless}
      />
    </AuthPageLayout>
  );
};

export default SignUpPage;
