import "./index.css";
import App from "./App";
import { logError } from "ErrorService";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import { ApplicationError } from "Components/Error/ApplicationError";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseSentryUserFromJWT } from "Utils/TrackingUtils";
import { getSystemVersion } from "Utils/VersioningUtils";

registerSW({ immediate: true });

if (import.meta.env.VITE_SENTRY_IS_ENABLED === "true") {
  Sentry.init({
    dsn: "https://fbce6a956e9c9be57da0517c03e4227a@o4505367851368448.ingest.us.sentry.io/4507899531558912",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/coinwage/, "localhost"],
      }),
      new Sentry.HttpContext(),
      new Sentry.Dedupe(),
    ],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0,
    normalizeDepth: 8,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.extra?.doNotShowDialog !== true) {
        Sentry.showReportDialog({ eventId: event.event_id, lang: "cs" });
      }
      return event;
    },
    initialScope: scope => {
      //TODO maybe add later
      scope.setTag("version", getSystemVersion());
      const user = parseSentryUserFromJWT();
      if (user) {
        scope.setUser(user);
        scope.setTag("user.login", user.username);
      }

      return scope;
    },
    release: getSystemVersion(),
    autoSessionTracking: false,
  });
}

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_IS_ENABLED === "true") {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE as string,
  };
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={errorInfo => <ApplicationError error={errorInfo.error} />}
    showDialog={true}
    dialogOptions={{
      lang: "cs",
    }}
  >
    <Sentry.Profiler name="Moone app">
      <App />
    </Sentry.Profiler>
  </Sentry.ErrorBoundary>,
);

window.addEventListener("error", function (event) {
  logError(event.error);
});

const setVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
setVhUnit();
window.addEventListener("resize", () => {
  setVhUnit();
});
