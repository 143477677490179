import { UserInfoQueryResult, getAuthUserInfo } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getUserInfoAsync = createAsyncAction(
  "@auth/GET_USER_INFO_REQUEST",
  "@auth/GET_USER_INFO_SUCCESS",
  "@auth/GET_USER_INFO_FAILURE",
)<void, UserInfoQueryResult, Error>();

function* getUserInfo(
  action: ReturnType<typeof getUserInfoAsync.request>,
): Generator {
  try {
    const response = yield* call(getAuthUserInfo);
    if (response.status === 200) {
      yield put(getUserInfoAsync.success(response.json));
    } else {
      yield put(getUserInfoAsync.failure(new Error()));
    }
  } catch (err) {
    yield put(getUserInfoAsync.failure(err as Error));
  }
}
export function* getUserInfoSaga() {
  yield takeLatest(getType(getUserInfoAsync.request), getUserInfo);
}
