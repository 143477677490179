import { BalanceContentWrapper } from "Components/Balance/BalanceComponent";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon } from "Components/Shared/Icons";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";
import { AccountBalance } from "../Shared/AccountBalance";

const Button = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(2)};
  max-height: 52px;
  justify-content: flex-start;
  text-transform: none;
`;

const PageResources = Resources.Balance.BalanceContent;

export const NoBankAccount: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();
  const { t } = useResource();

  return (
    <BalanceContentWrapper>
      <AccountBalance balance={0}></AccountBalance>

      <UnstyledLink to={appUrl("banking-auth")}>
        <Button
          fullWidth
          color="primary"
          startIcon={<PlusMathIcon width={24} />}
        >
          {t(PageResources.NoBankAccountButton)}
        </Button>
      </UnstyledLink>
    </BalanceContentWrapper>
  );
};
