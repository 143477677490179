import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { FC, PropsWithChildren } from "react";
import { CustomerPaymentPayComponent } from "Components/Customer/Payment/CustomerPaymentPayComponent";
import { useSearchParams } from "react-router-dom";

const PageResources = Resources.Customer.Pay;

export const CustomerPaymentPayPage: FC<PropsWithChildren<unknown>> = _ => {
  const { t } = useResource();
  const [searchParams] = useSearchParams();

  const publicID = searchParams.get("publicID");
  const transactionPublicID = searchParams.get("transactionPublicID");

  return (
    <AuthenticatedLayout title={t(PageResources.Title)}>
      <StyledPageWrapper>
        <CustomerPaymentPayComponent
          publicID={publicID}
          transactionPublicID={transactionPublicID}
        />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default CustomerPaymentPayPage;
