import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type Props = { title: string; goBackUrl?: string; onChevronClick?: () => void };

const Wrapper = styled.div`
  display: flex;
  justify-items: flex-start;
  margin-top: ${props => props.theme.spacing(0)};

  ${props => props.theme.breakpoints.down("md")} {
    margin-top: ${props => props.theme.spacing(0)};
  }
`;

const ChevronWrapper = styled.div`
  cursor: pointer;
  margin-right: ${props => props.theme.spacing(2)};
  margin-top: 9px;

  ${props => props.theme.breakpoints.down("md")} {
    margin-top: 25px;
  }

  svg {
    height: 1.4em;
    width: 1.4em;
    color: ${props => props.theme.palette.primary.dark};
  }
`;

const Title = styled(Typography)`
  text-transform: uppercase;
  font-size: 30px;

  ${props => props.theme.breakpoints.down("md")} {
    margin-left: ${props => props.theme.spacing(2)};
  }
`;

export const PageTitle: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ title, goBackUrl, onChevronClick }) => {
  const navigate = useNavigate();
  return (
    <Wrapper className={!!goBackUrl ? "go-back-url" : "no-go-back-url"}>
      {(!!goBackUrl || onChevronClick) && (
        <ChevronWrapper
          className="chevron-wrapper"
          onClick={() =>
            !!onChevronClick
              ? onChevronClick()
              : !!goBackUrl && navigate(goBackUrl)
          }
        >
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-tzssek-MuiSvgIcon-root"
            viewBox="8 6 7.41 12"
          >
            <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
          </svg>
        </ChevronWrapper>
      )}
      <Title variant="h1">{title}</Title>
    </Wrapper>
  );
};
