import { Typography } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { BankAccountRowLink } from "Components/BankAddresses/BankAccountRowLink";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Wrapper = styled.div``;

export const BankAccountListPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  const { data: bankingAccounts, isLoading } = useBankingAccounts();

  return (
    <AuthenticatedLayout title="Bankovní účty" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          {!isLoading && !!bankingAccounts && (
            <Wrapper>
              <PrimaryPaper>
                <Typography fontSize={16}>
                  Tyto bankovní účty jsou spárovány s Vaším účtem. Pokud chcete
                  přidat další bankovní účet, klikněte na tlačítko "Spárovat
                  bankovní účet".
                </Typography>

                {bankingAccounts.items.length > 0 && (
                  <div>
                    <br />

                    {bankingAccounts.items.map(e => (
                      <BankAccountRowLink key={e.bankAccountID} item={e} />
                    ))}

                    <br />
                  </div>
                )}

                {!bankingAccounts.items.length && (
                  <Typography
                    marginTop={6}
                    marginBottom={6}
                    fontSize={16}
                    fontWeight={600}
                  >
                    Nemáte žádné spárované bankovní účty.
                  </Typography>
                )}

                <UnstyledLink to={appUrl("banking-auth")}>
                  <PrimaryButton color="info" fullWidth>
                    Spárovat bankovní účet
                  </PrimaryButton>
                </UnstyledLink>
              </PrimaryPaper>
            </Wrapper>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankAccountListPage;
