import type { FC } from "react";

//import airBank from "Assets/Images/Banks/ab.png";
import kb from "Assets/Images/Banks/kb.png";
//import rb from "Assets/Images/Banks/rb.png";
import csas from "Assets/Images/Banks/csas.png";
import csob from "Assets/Images/Banks/csob.png";
import type { Bank } from "Infrastructure/Api/Api";
import { Skeleton } from "@mui/material";

const iconsSrc: { [key in Bank]: string } = {
  CSOB: csob,
  CSAS: csas,
  KB: kb,
};

type Props = {
  bank?: Bank;
  name?: string;
  isLoading?: boolean;
};

const HEIGHT = 32;

export const BankImage: FC<Props> = ({ bank, name, isLoading }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" width={120} height={HEIGHT} />;
  }

  if (!bank) {
    return null;
  }

  return (
    <img src={iconsSrc[bank]} height={HEIGHT} alt={`${name || "bank"} logo`} />
  );
};
