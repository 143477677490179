import { Box, Typography } from "@mui/material";
import { BankSelectorComponent } from "Components/Banking/Auth/BankSelectorComponent";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.Banking.Auth;

export const BankingAuthPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  const { t } = useResource();

  useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  return (
    <AuthenticatedLayout title={t(PageResources.Title)}>
      <StyledPageWrapper>
        <div>
          <Box marginBottom={5}>
            <Typography>Nějaký pokec co tě čeká</Typography>
          </Box>
          <BankSelectorComponent />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankingAuthPage;
