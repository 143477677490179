import * as React from "react";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { string, object, ObjectSchema, boolean, StringSchema } from "yup";
import { Link, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import styled from "styled-components";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { StyledContainer } from "Components/Auth/Styles";

type Props = {
  isLoading: boolean;
  serverError: string | null;
  defaultLogin: string | null;
  isPasswordless?: boolean;
  onSubmit: (formData: FormData) => void;
  onReset: () => void;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(2)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const StyledServerError = styled(Typography)`
  color: ${props => props.theme.colors.red};
`;

export type FormData = {
  login: string;
  password?: string;
  agreeWithTermsAndConditions: boolean;
};

export const SignUpForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();

  const { isLoading, serverError, defaultLogin, isPasswordless } = props;

  const validationSchema: ObjectSchema<FormData> = object({
    login: string()
      .email("E-mail není validní")
      .required(t(Resources.Validation.Required)),
    password: (isPasswordless
      ? string().notRequired()
      : string().required(
          t(Resources.Validation.Required),
        )) as StringSchema<string>,
    agreeWithTermsAndConditions: boolean()
      .required()
      .isTrue("Musíte souhlasit s obch. podmínkami"),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      login: defaultLogin ?? "",
      password: "",
      agreeWithTermsAndConditions: false,
    },
  });

  React.useEffect(() => {
    if (serverError) {
      setError("login", {
        type: "required",
        message: "",
      });
    }
  }, [serverError, setError]);

  const onSubmit = (data: FormData) => {
    props.onSubmit(data);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <StyledContainer>
        <div>
          <FormInput
            autoFocus
            control={control}
            name="login"
            errors={errors}
            label="E-mail"
            fullWidth={true}
          ></FormInput>
          {!isPasswordless && (
            <FormInput
              control={control}
              name="password"
              errors={errors}
              label="Heslo"
              type="password"
              autoComplete={"current-password"}
              fullWidth={true}
            ></FormInput>
          )}
          <CheckboxInput
            control={control}
            name="agreeWithTermsAndConditions"
            errors={errors}
            label={
              <StyledFlex gap="5px">
                <Typography>Souhlasím s</Typography>
                <Link
                  target="_blank"
                  href="https://www.coinwage.io/obchodni-podminky"
                >
                  obchodními podmínkami
                </Link>
              </StyledFlex>
            }
          ></CheckboxInput>

          <StyledServerError variant="subtitle1">
            {serverError}
          </StyledServerError>

          <StyledSubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="info"
            isLoading={isLoading}
          >
            {"Vytvořit účet"}
          </StyledSubmitButton>
        </div>
      </StyledContainer>
    </StyledForm>
  );
};
