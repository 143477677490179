import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { useNavigate } from "react-router";
import { appUrl } from "Utils/UrlUtils";
import { FC, PropsWithChildren, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const MenuResources = Resources.Menu.MainMenuItems;

export const CustomerPaymentsCallbackPage: FC<
  PropsWithChildren<unknown>
> = _ => {
  const { t } = useResource();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // This should not happen, but just in case
    if (searchParams.has("transactionPublicID")) {
      // TODO: Redirect to payment detail page
      navigate(appUrl(`dashboard`));
    } else {
      navigate(appUrl(`dashboard`));
    }
  });

  return (
    <AuthenticatedLayout title={t(MenuResources.InitiatePayment)}>
      <StyledPageWrapper>
        <div>Probíhá přesměrování, prosím vyčkejte...</div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default CustomerPaymentsCallbackPage;
