import * as React from "react";
export const ForwardArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.99998 44.9961C4.98436 44.9961 4.97264 44.9961 4.95701 44.9961C4.41795 44.9727 3.99217 44.5274 3.99998 43.9883C4.00389 43.7422 4.57811 19.6797 28 19.0156V12C28 11.6133 28.2226 11.2617 28.5703 11.0977C28.914 10.9297 29.332 10.9805 29.6289 11.2227L45.6289 24.2227C45.8633 24.4141 46 24.6992 46 25C46 25.3008 45.8633 25.5859 45.6328 25.7774L29.6328 38.7774C29.332 39.0195 28.918 39.0664 28.5703 38.9024C28.2226 38.7383 28 38.3867 28 38V31.0078C7.16014 31.3867 6.03514 43.543 5.99608 44.0664C5.96092 44.5938 5.52342 44.9961 4.99998 44.9961Z"
        fill="currentColor"
      />
    </svg>
  );
};
