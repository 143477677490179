import { logError } from "ErrorService";
import { hotjar } from "react-hotjar";
import { JWT_KEY } from "Utils/AuthUtils";

const isHotJarEnabled = import.meta.env.VITE_HOTJAR_IS_ENABLED === "true";

export function initializeHotjar() {
  if (isHotJarEnabled) {
    hotjar.initialize(2952025, 6);
    setHotjarIdentification();
  }
}

export function setHotjarIdentification() {
  if (!isHotJarEnabled) {
    return;
  }
  try {
    var isHotjarIdentified = (window as any).isHotjarIdentified as
      | boolean
      | undefined;

    if (isHotjarIdentified === true) {
      return;
    }
    var token = localStorage.getItem(JWT_KEY);

    if (!token) {
      return;
    }

    var login = JSON.parse(window.atob(token.split(".")[1])).unique_name;

    hotjar.identify(login, {});

    (window as any).isHotjarIdentified = true;
  } catch (error) {
    logError(error as Error);
  }
}
