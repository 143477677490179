import { ChevronRight } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledGrid = styled(Grid)<{
  $color?: string;
}>`
  cursor: pointer;
  color: ${props => props.theme.palette.primary.dark};

  ${props => props.theme.breakpoints.up("md")} {
    &:hover {
      color: ${props => props.theme.palette.primary.main};
    }
  }
  margin-left: 5px;

  ${props =>
    props.$color &&
    `
    color: ${props.$color};

      &:hover {
        color: ${props.$color};
      }
    `}
`;

const StyledChevron = styled(ChevronRight)`
  color: ${props => props.theme.palette.primary.main};
`;

const IconWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;
  }
`;

type Props = {
  name: string;
  url: string;
  icon: JSX.Element;
  color?: string;
  rightIcon?: JSX.Element;
};

export const SettingsRowLink: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ name, url, icon, color, rightIcon }) => {
  const navigate = useNavigate();

  return (
    <>
      <StyledGrid
        item
        container
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        onClick={() => navigate(url)}
        $color={color}
      >
        <Grid item xs={1}>
          <IconWrapper>{icon}</IconWrapper>
        </Grid>
        <Grid item xs={10}>
          <Typography fontSize={16}>{name}</Typography>
        </Grid>
        <Grid item xs={1}>
          {!!rightIcon ? rightIcon : <StyledChevron />}
        </Grid>
      </StyledGrid>
      <Divider />
    </>
  );
};
