import { useQuery } from "@tanstack/react-query";
import { getTransactions } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  offset?: number;
  limit?: number;
};

export const useTransactions = ({ offset, limit }: Props = {}) =>
  useQuery({
    queryKey: ["TRANSACTIONS", { offset, limit }],
    queryFn: processResponse(() => getTransactions(offset, limit)),
  });
