import { Box, Skeleton, Typography } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { useBankingAuthQuery } from "Api/Mutations/useBankingAuth";
import { useBanksEnvironment } from "Api/Queries/Banks/useBanksEnvironments";
import { BankTile } from "Components/Shared/BankTile";

const PageResources = Resources.Banking.Auth.BankList;

export const BankSelectorComponent = () => {
  const { data, isLoading } = useBanksEnvironment();

  const {
    isPending,
    mutate,
    variables: selectedEnvironment,
  } = useBankingAuthQuery({
    onAuthorizeUrl: ({ url }) => {
      window.location.href = url;
    },
  });
  const { t } = useResource();

  const handleAuthorize = (env: BankEnvironment) => () => {
    if (!isPending) {
      mutate(env);
    }
  };

  return (
    <div>
      <Typography fontWeight={600} marginBottom={2}>
        {t(PageResources.Title)}
      </Typography>
      <Box display="flex" flexDirection="column" gap={4}>
        {isLoading ? (
          <BankTilesLoader />
        ) : (
          <>
            {data?.items
              ?.filter(({ isVisible }) => isVisible)
              ?.map(({ bank, name, isEnabled, bankEnvironment }) => (
                <BankTile
                  key={bank}
                  bank={bank}
                  name={name}
                  onAuthorize={handleAuthorize(bankEnvironment)}
                  isLoading={
                    selectedEnvironment === BankEnvironment.CSAS_SANDBOX
                  }
                  disabled={!isEnabled}
                />
              ))}
          </>
        )}
      </Box>
    </div>
  );
};

const BankTilesLoader = () => {
  return (
    <>
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
      <Skeleton variant="rectangular" height={52} />
    </>
  );
};
