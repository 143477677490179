import { BankAccountDetail } from "Components/BankAddresses/BankAccountDetail";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBankAddressDetailAsync } from "State/BankAddresses/Detail/BankAddressDetailState";
import { RootStateType } from "State/Store";
import { setBankAccountVerificationInProgressAsync } from "State/Verification/BankAccount/SetBankAccountVerificationInProgress";

export const BankAccountStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getBankAddressDetailAsync.request({ externalID: null }));
  }, [dispatch]);

  const { bankAccountDetail } = useSelector(
    (e: RootStateType) => e.bankAccount,
  );

  const submit = () => {
    dispatch(setBankAccountVerificationInProgressAsync.request());
  };

  return (
    <>
      <br />
      {!!bankAccountDetail && <BankAccountDetail detail={bankAccountDetail} />}
      <br />
      <br />
      <br />
      <PrimaryButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={submit}
      >
        {"Platba odeslána"}
      </PrimaryButton>
    </>
  );
};
