import { FC, PropsWithChildren, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import styled from "styled-components";
import { useTransactions } from "Api/Queries/Transactions/useTransactions";
import { appUrl } from "Utils/UrlUtils";
import { TransactionItem } from "Components/Shared/Transactions/TransactionItem";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

type Props = {
  limit?: number;
  isShowMoreShown?: boolean;
  isExportShown?: boolean;
};

const ClickableText = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: 600;
`;

export const TransactionListTable: FC<PropsWithChildren<Props>> = ({
  limit = 5,
  isShowMoreShown,
  isExportShown,
}) => {
  const { data: transactions, isLoading } = useTransactions({
    limit,
    offset: 0,
  });

  const transactionItems = transactions?.items ?? [];

  const [itemLimit, setItemLimit] = useState(limit);

  const [offset, setOffset] = useState(transactions?.offset ?? 0);

  if (!isLoading && transactionItems.length === 0) {
    if (!isShowMoreShown) {
      return null;
    }

    return (
      <Typography marginTop={theme => theme.spacing(2)}>
        {"Zatím nebyla provedena žádná transakce."}
      </Typography>
    );
  }

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent={"space-between"}
        alignItems={"stretch"}
      >
        <Grid item>
          <Typography fontWeight={600}>Transakce</Typography>
        </Grid>
        {isExportShown && (
          <Grid>
            <ClickableText
            //onClick={() => dispatch(downloadTransactionCsvAsync.request())}
            >
              Exportovat
            </ClickableText>
          </Grid>
        )}
      </Grid>
      {isLoading && !transactions
        ? Array.from({ length: limit }).map((_, index) => (
            <Skeleton key={index} height={62} />
          ))
        : transactions?.items?.map(e => (
            <TransactionItem key={e.publicID} item={e} direction="incoming" />
          ))}
      <ButtonWrapper>
        {isShowMoreShown && itemLimit < (transactions?.totalCount ?? 0) && (
          <ClickableText
            onClick={() => {
              setOffset(offset + limit);
              setItemLimit(1000);
            }}
          >
            Načíst další
          </ClickableText>
        )}
      </ButtonWrapper>

      {isShowMoreShown && (
        <Box textAlign="center">
          <UnstyledLink to={appUrl("transactions")}>
            {"Načíst další"}
          </UnstyledLink>
        </Box>
      )}
    </>
  );
};
