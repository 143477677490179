import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  RiskProfileStatus,
  SourceOfIncome,
  UserAdminDetailDto,
  UserStatus,
} from "Api/Api";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import {
  boolean,
  mixed,
  number,
  NumberSchema,
  object,
  ObjectSchema,
  string,
  StringSchema,
} from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { enumToCodeList } from "Utils/EnumUtils";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";

type Props = {
  user: UserAdminDetailDto;
  onSubmit: (model: UserAdminDetailDto) => void;
};

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${props => props.theme.spacing(4, 0)};
`;

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(1)};
`;

export const UserDetailForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();

  const { user } = props;

  const validationSchema: ObjectSchema<UserAdminDetailDto> = object({
    userID: number().defined(),
    login: string()
      .email("E-mail není validní")
      .required(t(Resources.Validation.Required)),
    email: string()
      .email("E-mail není validní")
      .required(t(Resources.Validation.Required)),
    status: mixed<UserStatus>().oneOf(Object.values(UserStatus)).defined(),
    firstName: string().nullable() as StringSchema<string>,
    lastName: string().nullable() as StringSchema<string>,
    verificationSourceOfIncome: mixed<SourceOfIncome>()
      .oneOf(Object.values(SourceOfIncome))
      .defined(),
    verificationAdditionalInfoStatus: mixed<AdditionalInfoStatus>()
      .oneOf(Object.values(AdditionalInfoStatus))
      .defined(),
    verificationRiskProfile: mixed<RiskProfileStatus>()
      .oneOf(Object.values(RiskProfileStatus))
      .defined(),
    verificationHasMoneyFromCriminalActivityOrIsFinancingTerrorism:
      boolean().defined(),
    verificationHasConnectionToSanctionedCountry: boolean().defined(),
    verificationIsPoliticallyExposedPerson: boolean().defined(),
    verificationIdentityVerificationStatus: mixed<IdentityVerificationStatus>()
      .oneOf(Object.values(IdentityVerificationStatus))
      .defined(),
    verificationBankVerificationStatus: mixed<BankVerificationStatus>()
      .oneOf(Object.values(BankVerificationStatus))
      .defined(),
    verificationVerificationJson: string().nullable() as StringSchema<string>,
    verificationExternalID: string().nullable() as StringSchema<string>,
    verificationBirthnumberSha256: string().nullable() as StringSchema<string>,
    verificationDailyBuyLimit: number()
      .defined()
      .nullable() as NumberSchema<number>,
    verificationMonthlyBuyLimit: number()
      .defined()
      .nullable() as NumberSchema<number>,
    hasIdentificationsUploaded: boolean().defined(),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserAdminDetailDto>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: user,
  });

  return (
    <div>
      <StyledForm onSubmit={handleSubmit(props.onSubmit)}>
        <FormInput
          control={control}
          name="login"
          errors={errors}
          label="Login"
        ></FormInput>

        <FormInput
          control={control}
          name="email"
          errors={errors}
          label="E-mail"
        ></FormInput>

        <Dropdown
          control={control}
          name="status"
          errors={errors}
          label="Status"
          codeList={enumToCodeList(UserStatus)}
        />

        <FormInput
          control={control}
          name="firstName"
          errors={errors}
          label="Jméno"
        ></FormInput>

        <FormInput
          control={control}
          name="lastName"
          errors={errors}
          label="Příjmení"
        ></FormInput>

        <FormInput
          control={control}
          name="verificationDailyBuyLimit"
          errors={errors}
          label="Denní limit"
          mask={Number}
          isNumber
        ></FormInput>

        <FormInput
          control={control}
          name="verificationMonthlyBuyLimit"
          errors={errors}
          label="Měsíční limit"
          mask={Number}
          isNumber
        ></FormInput>

        <Dropdown
          control={control}
          name="verificationSourceOfIncome"
          errors={errors}
          label="SourceOfIncome"
          codeList={enumToCodeList(SourceOfIncome)}
        />

        <CheckboxInput
          control={control}
          name="verificationHasMoneyFromCriminalActivityOrIsFinancingTerrorism"
          errors={errors}
          label="HasMoneyFromCriminalActivityOrIsFinancingTerrorism"
        />
        <br />

        <CheckboxInput
          control={control}
          name="verificationHasConnectionToSanctionedCountry"
          errors={errors}
          label="HasConnectionToSanctionedCountry"
        />
        <br />

        <CheckboxInput
          control={control}
          name="verificationIsPoliticallyExposedPerson"
          errors={errors}
          label="IsPoliticallyExposedPerson"
        />
        <br />

        <Dropdown
          control={control}
          name="verificationAdditionalInfoStatus"
          errors={errors}
          label="AdditionalInfoStatus"
          codeList={enumToCodeList(AdditionalInfoStatus)}
        />

        <Dropdown
          control={control}
          name="verificationRiskProfile"
          errors={errors}
          label="RiskProfile"
          codeList={enumToCodeList(RiskProfileStatus)}
        />

        <Dropdown
          control={control}
          name="verificationIdentityVerificationStatus"
          errors={errors}
          label="IdentityVerificationStatus"
          codeList={enumToCodeList(IdentityVerificationStatus)}
        />

        <Dropdown
          control={control}
          name="verificationBankVerificationStatus"
          errors={errors}
          label="BankVerificationStatus"
          codeList={enumToCodeList(BankVerificationStatus)}
        />

        <FormInput
          control={control}
          name="verificationVerificationJson"
          errors={errors}
          label="VerificationJson"
          multiline={true}
        ></FormInput>

        <FormInput
          control={control}
          name="verificationExternalID"
          errors={errors}
          label="VerificationExternalID"
        ></FormInput>

        <FormInput
          control={control}
          name="verificationBirthnumberSha256"
          errors={errors}
          label="BirthnumberSha256"
          multiline={true}
        ></FormInput>

        <StyledSubmitButton
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          {"Uložit"}
        </StyledSubmitButton>
      </StyledForm>
    </div>
  );
};
