import { ApplicationLink } from "Components/Layout/Menu/Menu";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { getAppUrl } from "Utils/UrlUtils";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

type Props = { item: ApplicationLink; pathname: string };

const StyledLink = css`
  text-decoration: none;
  color: #555555;
  svg {
    width: 50px;
    height: 50px;
    color: #555555;
  }

  > div {
    width: 70%;
  }

  &.active {
    color: ${props => props.theme.palette.primary.main};

    > div {
      background-color: ${props => props.theme.palette.background.paper};
      border-radius: 10px;
    }

    svg {
      color: ${props => props.theme.palette.primary.main};
    }
  }
  ${props => props.theme.breakpoints.up("md")} {
    &:hover {
      color: ${props => props.theme.palette.primary.main};

      svg {
        color: ${props => props.theme.palette.primary.main};
      }
    }
  }

  svg {
    height: 24px;
    align-self: center;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${StyledLink}
`;

const StyledAnchor = styled.a`
  ${StyledLink}
`;

const ContentWrapper = styled(StyledFlex)`
  padding: ${props => props.theme.spacing(3, 2)};
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

const Content: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { item } = props;

  return (
    <ContentWrapper alignItems="center">
      {item.icon}

      <div>{item.text}</div>
    </ContentWrapper>
  );
};
export const MenuItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { item, pathname } = props;
  const appUrl = getAppUrl();

  const className =
    pathname === "/" && item.to === appUrl("dashboard") ? "active" : "";

  return item.isDisabled === true ? (
    <div key={item.to}>{item.text}</div>
  ) : (
    <div onClick={item.onClick} key={item.to}>
      {item.isExternalLink ? (
        <StyledAnchor href={item.to} target="_blank">
          <Content pathname={pathname} item={item} />
        </StyledAnchor>
      ) : (
        <StyledNavLink className={className} to={{ pathname: item.to }}>
          <Content pathname={pathname} item={item} />
        </StyledNavLink>
      )}
    </div>
  );
};
