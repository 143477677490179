import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import {
  postNotificationGetNotificationSettings,
  postNotificationSaveNotificationSettings,
  SaveUserNotificationSettingsCommandResult,
  UserNotificationSettingsDto,
} from "Api/Api";
import { toast } from "react-toastify";

export const getUserNotificationSettingsAsync = createAsyncAction(
  "@notification/GET_USER_NOTIFICATION_SETTINGS_REQUEST",
  "@notification/GET_USER_NOTIFICATION_SETTINGS_SUCCESS",
  "@notification/GET_USER_NOTIFICATION_SETTINGS_FAILURE",
)<void, UserNotificationSettingsDto | null, Error>();

export const saveUserNotificationSettingsAsync = createAsyncAction(
  "@notification/SAVE_USER_NOTIFICATION_SETTINGS_REQUEST",
  "@notification/SAVE_USER_NOTIFICATION_SETTINGS_SUCCESS",
  "@notification/SAVE_USER_NOTIFICATION_SETTINGS_FAILURE",
)<
  UserNotificationSettingsDto,
  SaveUserNotificationSettingsCommandResult,
  Error
>();

function* getUserNotificationSettings(
  action: ReturnType<typeof getUserNotificationSettingsAsync.request>,
): Generator {
  try {
    const response = yield* call(postNotificationGetNotificationSettings);
    if (response.status === 200) {
      yield put(getUserNotificationSettingsAsync.success(response.json));
    } else {
      yield put(
        getUserNotificationSettingsAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserNotificationSettingsAsync.failure(err as Error));
  }
}
export function* getUserNotificationSettingsSaga() {
  yield takeLatest(
    getType(getUserNotificationSettingsAsync.request),
    getUserNotificationSettings,
  );
}

function* saveUserNotificationSettings(
  action: ReturnType<typeof saveUserNotificationSettingsAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postNotificationSaveNotificationSettings,
      action.payload,
    );
    if (response.status === 200) {
      toast.success("Nastavení bylo uloženo.");
      yield put(saveUserNotificationSettingsAsync.success(response.json));
    } else {
      yield put(
        saveUserNotificationSettingsAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(saveUserNotificationSettingsAsync.failure(err as Error));
  }
}
export function* saveUserNotificationSettingsSaga() {
  yield takeLatest(
    getType(saveUserNotificationSettingsAsync.request),
    saveUserNotificationSettings,
  );
}
