import { Grid, Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Colors } from "Components/Layout/Themes/Colors";
import { SettingsRowLink } from "Components/Settings/SettingsRowLink";
import {
  FriendsIcon,
  InstagramVerificationBadgeIcon,
  MultiplyIcon,
  NotificationIcon,
  TrashBinIcon,
  UserIcon,
} from "Components/Shared/Icons";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch } from "react-redux";
import { getLeadListAsync } from "State/Afl/LeadListState/LeadListState";
import { getAppUrl } from "Utils/UrlUtils";

export const SettingsPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLeadListAsync.request());

    return () => {
      dispatch(getLeadListAsync.success(null));
    };
  }, [dispatch]);

  return (
    <AuthenticatedLayout title="Nastavení">
      <StyledPageWrapper>
        <div className="left-content">
          <div>
            {/* <UserLimits /> */}
            <br />
            <br />
            <Grid container spacing={4}>
              <Grid item>
                <Typography fontWeight={600} color={Colors.DarkGrey}>
                  Nastavení
                </Typography>
              </Grid>

              <SettingsRowLink
                name="Profil"
                url={appUrl("account")}
                icon={<UserIcon />}
              />
              {/*<SettingsRowLink
                name="Ověření účtu"
                url={appUrl("verification")}
                icon={<VerificationBadgeIcon />}
              />*/}
              {/* <SettingsRowLink
                name="Uchování bitcoinů"
                url={appUrl("account-settings")}
                icon={<MoneyBoxIcon />}
              /> */}
              <SettingsRowLink
                name="Připojené účty"
                url={appUrl("bank-address-list")}
                icon={<InstagramVerificationBadgeIcon />}
              />
              <SettingsRowLink
                name="Notifikace a upozornění"
                url={appUrl("notification-settings")}
                icon={<NotificationIcon />}
              />
              <SettingsRowLink
                name="Pozvat přítele"
                url={appUrl("affiliate")}
                icon={<FriendsIcon />}
              />

              <SettingsRowLink
                name="Smazat účet"
                url={appUrl("deactivate-user")}
                icon={<TrashBinIcon />}
                color={Colors.Red}
                rightIcon={
                  <MultiplyIcon
                    style={{ marginLeft: "5px" }}
                    width={12}
                    height={12}
                  />
                }
              />
            </Grid>
          </div>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SettingsPage;
