import { Box, Typography } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { FC } from "react";

type Props = {
  withoutPaper: boolean;
  paddingY?: number;
};

export const ErrorMessage: FC<Props> = ({ withoutPaper, paddingY = 3 }) => {
  if (withoutPaper) {
    return (
      <Box paddingY={paddingY}>
        <Typography color="red">
          Během načítání se objevili nějaké problémy :( Zkuste akci později.
        </Typography>
      </Box>
    );
  }

  return (
    <PrimaryPaper>
      Během načítání se objevili nějaké problémy :( Zkuste akci později.
    </PrimaryPaper>
  );
};
