import {
  EmailVerificationCommand,
  EmailVerificationCommandResult,
  EmailVerificationCommandResultStatus,
  postAuthVerifyEmail,
  SignInStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { NavigateFunction } from "react-router-dom";
import { setToken } from "Utils/LocalStorageUtils";
import { getAppUrl } from "Utils/UrlUtils";
import { toast } from "react-toastify";
import { afterSignInSuccess } from "Utils/AuthUtils";

export const verifyEmailAsync = createAsyncAction(
  "@auth/VERIFY_EMAIL_REQUEST",
  "@auth/VERIFY_EMAIL_SUCCESS",
  "@auth/VERIFY_EMAIL_FAILURE",
)<
  { command: EmailVerificationCommand; navigate: NavigateFunction },
  EmailVerificationCommandResult,
  Error
>();

function* verifyEmail(
  action: ReturnType<typeof verifyEmailAsync.request>,
): Generator {
  try {
    const response = yield* call(postAuthVerifyEmail, action.payload.command);

    if (
      response.status === 200 &&
      response.json.status === EmailVerificationCommandResultStatus.Success
    ) {
      yield put(verifyEmailAsync.success(response.json));
      if (!!response.json.signInResult) {
        setToken(response.json.signInResult);
      }

      if (response.json.signInResult?.status === SignInStatus.Success) {
        yield* call(
          afterSignInSuccess,
          response.json.signInResult,
          action.payload.navigate,
        );
      }

      const url = getAppUrl();
      yield* call(() => action.payload.navigate(url("dashboard")));
      toast.success("E-mail byl úspěšně ověřen.");
    } else {
      yield* call(() => action.payload.navigate("/"));
      yield put(
        verifyEmailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(verifyEmailAsync.failure(err as Error));
  }
}
export function* verifyEmailSaga() {
  yield takeLatest(getType(verifyEmailAsync.request), verifyEmail);
}
