import { Skeleton, Typography } from "@mui/material";
import { BankImage } from "Components/Shared/BankImage";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { Bank } from "Infrastructure/Api/Api";
import { CurrencyCode } from "Models/CurrencyCodes";
import styled from "styled-components";

type Props = {
  balance?: number;
  isLoading?: boolean;
  bank?: Bank;
  prefix?: string;
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 5px;
`;

export const AccountBalance: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ balance, isLoading, bank, prefix }) => {
  const currencySymbol = useCurrencySymbol(CurrencyCode.CZK);

  const formattedValue =
    balance === 0
      ? "0"
      : `${prefix || ""} ${new Intl.NumberFormat("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(balance ?? 0)}`;

  return (
    <StyledFlex justifyContent="space-between" alignItems="flex-end">
      <Wrapper>
        <Typography variant="h4" fontWeight={400} fontSize={38}>
          {isLoading ? <Skeleton width={140} /> : formattedValue}
        </Typography>
        {!isLoading && <Currency fontSize={16}>{currencySymbol}</Currency>}
      </Wrapper>
      <BankImage bank={bank} isLoading={isLoading} />
    </StyledFlex>
  );
};
