import * as React from "react";
export const HistoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_308_671)">
        <path
          d="M37.0937 0.0937709C36.3164 0.16799 35.6523 0.691427 35.3984 1.42971C35.1406 2.1719 35.3398 2.99221 35.9062 3.53127L42.375 10H1.99996C1.93746 9.99611 1.87496 9.99611 1.81246 10C0.706992 10.0508 -0.14457 10.9883 -0.0937893 12.0938C-0.043008 13.1992 0.894492 14.0508 1.99996 14H42.375L35.9062 20.4688C35.3828 20.9688 35.1679 21.711 35.3476 22.4141C35.5273 23.1133 36.0703 23.6641 36.7695 23.8516C37.4687 24.0391 38.2148 23.8321 38.7187 23.3125L50.0312 12L38.7187 0.687521C38.2968 0.253927 37.6992 0.0351772 37.0937 0.0937709ZM12.5 26.0938C12.0468 26.1524 11.6289 26.3594 11.3125 26.6875L-3.92497e-05 38L11.3125 49.3125C11.8164 49.8321 12.5625 50.0391 13.2617 49.8516C13.9609 49.6641 14.5039 49.1133 14.6836 48.4141C14.8632 47.711 14.6484 46.9688 14.125 46.4688L7.65621 40H48C48.7226 40.0117 49.3906 39.6328 49.7539 39.0078C50.1211 38.3867 50.1211 37.6133 49.7539 36.9922C49.3906 36.3672 48.7226 35.9883 48 36H7.65621L14.125 29.5313C14.7539 28.9375 14.9296 28.0039 14.5586 27.2227C14.1875 26.4414 13.3593 25.9844 12.5 26.0938Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_671">
          <rect width={50} height={50} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
