import {
  PasswordlessSignUpCommand,
  PasswordlessSignUpCommandResult,
  postAuthSignUpPasswordless,
  SignInResult,
  SignInStatus,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import { setToken } from "Utils/LocalStorageUtils";
import { LocalStorageKeys } from "Utils/LocalStorageKeys";
import { setSignUpSubsidy } from "State/Voucher/VoucherReducer";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { NavigateFunction } from "react-router";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { HttpStatusCode } from "Models/HttpStatusCodes";

export const signUpPasswordlessAsync = createAsyncAction(
  "@auth/SIGN_UP_PASSWORDLESS_REQUEST",
  "@auth/SIGN_UP_PASSWORDLESS_SUCCESS",
  "@auth/SIGN_UP_PASSWORDLESS_FAILURE",
)<
  {
    model: PasswordlessSignUpCommand;
    navigate: NavigateFunction;
  },
  PasswordlessSignUpCommandResult,
  Error
>();

function* signUpPasswordlessState(
  action: ReturnType<typeof signUpPasswordlessAsync.request>,
): Generator {
  try {
    const signUpResponse = yield* call(
      postAuthSignUpPasswordless,
      action.payload.model,
    );
    if (signUpResponse.status === 200) {
      const signInResult = signUpResponse.json.signInResult as SignInResult;
      if (
        !!signInResult &&
        signInResult.status === SignInStatus.Success &&
        !!signUpResponse.json.signInResult
      ) {
        localStorage.removeItem(LocalStorageKeys.VoucherCodeSubsidy);

        setToken(signInResult);

        yield put(getCodeListCollectionAsync.request());
        trackUserIdentification();

        yield put(signInAsync.success(signInResult));
        yield* call(
          afterSignInSuccess,
          signUpResponse.json.signInResult,
          action.payload.navigate,
        );

        if (
          !!signUpResponse.json.subsidyFiatAmount &&
          !!signUpResponse.json.subsidyFiatCurrencyCode
        ) {
          yield put(
            setSignUpSubsidy({
              fiatAmount: signUpResponse.json.subsidyFiatAmount,
              currencyCode: signUpResponse.json.subsidyFiatCurrencyCode,
            }),
          );
        }
      } else {
        yield put(
          signUpAsync.failure(
            new Error(signUpResponse.json.status, {
              cause: HttpStatusCode.Unauthorized,
            }),
          ),
        );
      }
    } else {
      yield put(signUpPasswordlessAsync.failure(new Error()));
    }
  } catch (err) {
    yield put(signUpPasswordlessAsync.failure(err as Error));
  }
}
export function* signUpPasswordlessSaga() {
  yield takeLatest(
    getType(signUpPasswordlessAsync.request),
    signUpPasswordlessState,
  );
}
