import { IdentityProvider } from "Api/Api";

export type AppUser = {
  login: string;
  firstName: string;
  lastName: string;
  accessRightCodes: string[];
  isFirstSignIn: boolean;
  tokenExpiration: string;
  userIdentityProviders: IdentityProvider[];
  profilePicture: string | null;
};

export enum SignInStep {
  Credentials,
  VerificationToken,
}

export enum ResetPasswordStep {
  Email,
  Success,
}

export enum SetPasswordStep {
  Password,
  Success,
}
