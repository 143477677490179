import { Skeleton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAsync } from "State/Balance/BalanceReducer";
import { NoBankAccount } from "Components/Balance/NoBankAccount";
import type { RootStateType } from "State/Store";
import styled from "styled-components";
import type { AppUser } from "State/Auth/Models/AuthStateModels";
import { BalanceActions } from "Components/Balance/BalanceActions";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Resources, useResource } from "Translations/Resources";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { AccountBalance } from "Components/Shared/AccountBalance";
import { PaperTitle } from "Components/Shared/PaperTitle";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { ErrorMessage } from "Components/Shared/ErrorMessage";

const StyledCoinWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
  }

  svg {
    width: 19px;
    height: auto;
  }
`;

const InternalContent: React.FunctionComponent<
  React.PropsWithChildren<{
    user: AppUser;
    isLoading: boolean;
    accounts?: BankAccountListItem[];
    isError?: boolean;
  }>
> = ({ user, isLoading, accounts, isError }) => {
  const isLoaded = !isLoading;

  if (isError) {
    return (
      <BalanceContentWrapper>
        <ErrorMessage withoutPaper />
      </BalanceContentWrapper>
    );
  }

  if (isLoaded && !accounts?.length && !isError) {
    return <NoBankAccount />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  const sortedAccounts = accounts?.sort((a, b) => b.rank - a.rank);
  const firstAccount = sortedAccounts?.[0];

  return (
    <BalanceContentWrapper>
      <div>
        <div>
          {isLoaded && (
            <AccountBalance
              balance={firstAccount?.balance}
              bank={firstAccount?.bank}
              isLoading={isLoading}
            />
          )}
          {!isLoaded && <Skeleton height={70} width="60%" />}
        </div>

        {/*<div>
          {isLoaded && (
            <Profits
              amount={balance.profitFiat}
              currency={balance.fiatCurrencyCode}
              percents={balance.profitPercent}
            />
          )}
          {!isLoaded && <Skeleton height={40} width="30%" />}
        </div>*/}
        {isLoaded && (
          <BalanceActions
            //balance={balance}
            user={user}
            publicID={firstAccount?.publicID ?? ""}
          />
        )}
        {!isLoaded && (
          <div>
            <Skeleton height={50} width="90%" />
          </div>
        )}
      </div>
    </BalanceContentWrapper>
  );
};

const PageResources = Resources.Balance.BalanceContent;

export const BalanceComponent: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  const { data: accounts, isLoading, isLoadingError } = useBankingAccounts();

  React.useEffect(() => {
    dispatch(getBalanceAsync.request());
  }, [dispatch]);

  const { user } = useSelector((e: RootStateType) => e.auth);

  if (!user) {
    return null;
  }

  return (
    <>
      <InternalContent
        user={user}
        accounts={accounts?.items}
        isLoading={isLoading}
        isError={isLoadingError}
      />
    </>
  );
};

export const BalanceContentWrapper: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = props => {
  const { t } = useResource();

  return (
    <PrimaryPaper>
      <StyledCoinWrapper>
        <PaperTitle>{t(PageResources.Title)}</PaperTitle>
      </StyledCoinWrapper>
      {props.children}
    </PrimaryPaper>
  );
};
