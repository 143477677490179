import { Typography } from "@mui/material";
import { useTransactions } from "Api/Queries/Transactions/useTransactions";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ErrorMessage } from "Components/Shared/ErrorMessage";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { RecentTransactions } from "Components/Shared/Transactions/RecentTransactions";
import { useNavigate } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";

export const TransactionsComponent = () => {
  const { t } = useResource();
  const navigate = useNavigate();
  const {
    data: transactions,
    isLoading,
    isError,
  } = useTransactions({ limit: 3 });

  return (
    <>
      <Typography marginTop={7} marginBottom={5} fontWeight={600}>
        {t(Resources.Transactions.Title)}
      </Typography>
      {isError ? (
        <ErrorMessage withoutPaper paddingY={0} />
      ) : (
        <>
          <RecentTransactions
            transactions={transactions?.items}
            isLoading={isLoading}
          />

          {!!transactions?.items?.length && (
            <StyledFlex justifyContent="center">
              <PrimaryButton
                variant="text"
                color="info"
                onClick={() => navigate(appUrl("transactions"))}
              >
                {t(Resources.Common.ShowAll)}
              </PrimaryButton>
            </StyledFlex>
          )}
        </>
      )}
    </>
  );
};
